import { atom } from 'recoil';
import { Transaction } from '../components/interface/transaction';

export const ordersState = atom<{
  totalPages: number
  totalTransactions: number
  transactions:[
         { transactionId: string,
          reference: string,
          client: string,
          totalTransactionValue: number,
          currency: string,
          transactionStatus: string,
          warehouse: string,
          transactionDate: string,
          paymentType:string,
          paymentGateway: string,
          workStation: string,
          invoiceNumber: string,
        }
  ]
}>({
  key: 'orders', // unique ID (with respect to other atoms/selectors)
  default: {
    totalPages: 0,
    totalTransactions: 0,
    transactions:[
      { transactionId: "",
        reference: "",
        client: "",
        totalTransactionValue: 0,
        currency: "",
        transactionStatus: "",
        warehouse: "",
        transactionDate: "",
        paymentType:"",
        paymentGateway: "",
        invoiceNumber: "",
        workStation: ""
      }
    ],
    
  },
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newID) => {
        // console.log('Current state:', newID);
      });
    },
  ],
});

