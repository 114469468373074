import { memo } from "react";
import { ClockIcon, CalendarIcon } from "@heroicons/react/outline";

export interface TableReturnsSkeletonProps {
  e2eAttr?: string;
}

export const TableTransactionSkeleton = memo(function (
  props: TableReturnsSkeletonProps
) {
  
  return (
    <>
    <div className="px-4 mt-8 sm:px-6 lg:px-2 -z-20">
      <div className="flex-col hidden mt-4 shadow-xl md:flex ">
        <div className="overflow-x-auto ">
          <div className="inline-block min-w-full py-2 align-middle md:px-3 lg:px-2">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-lg font-semibold text-gray-900 sm:pl-6"
                    >
                      Estado
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                    >
                      Monto y cliente
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                    >
                      Datos del pago
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                    >
                      Hora y Fecha
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 animate-pulse">
                  <tr className="cursor-pointer hover:bg-gray-300 active:bg-gray-300 focus:ring">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap md:pr-1 lg:pr-3 sm:pl-6 md:pl-4 lg:pl-6">
                      <span className="inline-block w-20 h-4 px-3 text-center bg-gray-300 rounded-full md:px-1 lg:px-3 md:w-20 lg:w-24"></span>
                    </td>
                    <td className="px-0 py-4 text-sm font-semibold text-gray-600 whitespace-nowrap lg:w-72 xl:w-auto">
                      <div className="flex flex-row">
                        <div className="flex justify-center flex-shrink-0 w-1/6 ">
                          <div className="w-20 h-8 bg-gray-300 rounded-lg">
                            {/* aqui la imagen */}
                          </div>
                        </div>
                        <div className="flex flex-col w-full px-3 lg:w-full">
                          <span className="w-32 h-4 bg-gray-300 rounded-full">
                            {/* aqui la el monto*/}
                          </span>
                          <span className="w-32 h-4 mt-1 bg-gray-300 rounded-full">
                            {/* aqui el correo del cliente*/}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm font-semibold text-gray-600 whitespace-nowrap ">
                      <div className="flex flex-col">
                        <span className="w-48 h-4 bg-gray-300 rounded-lg">
                          {/* aqui el ID*/}
                        </span>
                        <span className="w-24 h-4 mt-1 bg-gray-300 rounded-lg">
                          {/* aqui la REFERENCIA */}
                        </span>
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm font-semibold text-gray-600 whitespace-nowrap">
                      <div className="flex flex-col space-y-1">
                        <div className="flex flex-row content-center">
                          <ClockIcon aria-hidden="true" className="w-6 h-6" />
                          <span className="w-16 h-4 mt-1 ml-1 bg-gray-300 rounded-lg">
                            {/* aqui la HORA*/}
                          </span>
                        </div>

                        <div className="flex flex-row content-center">
                          <CalendarIcon
                            aria-hidden="true"
                            className="w-6 h-6"
                          />
                          <span className="w-16 h-4 mt-1 ml-1 bg-gray-300 rounded-lg">
                            {/* aqui la FECHA */}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="cursor-pointer hover:bg-gray-300 active:bg-gray-300 focus:ring">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap md:pr-1 lg:pr-3 sm:pl-6 md:pl-4 lg:pl-6">
                      <span className="inline-block w-20 h-4 px-3 text-center bg-gray-300 rounded-full md:px-1 lg:px-3 md:w-20 lg:w-24"></span>
                    </td>
                    <td className="px-0 py-4 text-sm font-semibold text-gray-600 whitespace-nowrap lg:w-72 xl:w-auto">
                      <div className="flex flex-row">
                        <div className="flex justify-center flex-shrink-0 w-1/6 ">
                          <div className="w-20 h-8 bg-gray-300 rounded-lg">
                            {/* aqui la imagen */}
                          </div>
                        </div>
                        <div className="flex flex-col w-full px-3 lg:w-full">
                          <span className="w-32 h-4 bg-gray-300 rounded-full">
                            {/* aqui la el monto*/}
                          </span>
                          <span className="w-32 h-4 mt-1 bg-gray-300 rounded-full">
                            {/* aqui el correo del cliente*/}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm font-semibold text-gray-600 whitespace-nowrap ">
                      <div className="flex flex-col">
                        <span className="w-48 h-4 bg-gray-300 rounded-lg">
                          {/* aqui el ID*/}
                        </span>
                        <span className="w-24 h-4 mt-1 bg-gray-300 rounded-lg">
                          {/* aqui la REFERENCIA */}
                        </span>
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm font-semibold text-gray-600 whitespace-nowrap">
                      <div className="flex flex-col space-y-1">
                        <div className="flex flex-row content-center">
                          <ClockIcon aria-hidden="true" className="w-6 h-6" />
                          <span className="w-16 h-4 mt-1 ml-1 bg-gray-300 rounded-lg">
                            {/* aqui la HORA*/}
                          </span>
                        </div>

                        <div className="flex flex-row content-center">
                          <CalendarIcon
                            aria-hidden="true"
                            className="w-6 h-6"
                          />
                          <span className="w-16 h-4 mt-1 ml-1 bg-gray-300 rounded-lg">
                            {/* aqui la FECHA */}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="cursor-pointer hover:bg-gray-300 active:bg-gray-300 focus:ring">
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap md:pr-1 lg:pr-3 sm:pl-6 md:pl-4 lg:pl-6">
                      <span className="inline-block w-20 h-4 px-3 text-center bg-gray-300 rounded-full md:px-1 lg:px-3 md:w-20 lg:w-24"></span>
                    </td>
                    <td className="px-0 py-4 text-sm font-semibold text-gray-600 whitespace-nowrap lg:w-72 xl:w-auto">
                      <div className="flex flex-row">
                        <div className="flex justify-center flex-shrink-0 w-1/6 ">
                          <div className="w-20 h-8 bg-gray-300 rounded-lg">
                            {/* aqui la imagen */}
                          </div>
                        </div>
                        <div className="flex flex-col w-full px-3 lg:w-full">
                          <span className="w-32 h-4 bg-gray-300 rounded-full">
                            {/* aqui la el monto*/}
                          </span>
                          <span className="w-32 h-4 mt-1 bg-gray-300 rounded-full">
                            {/* aqui el correo del cliente*/}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm font-semibold text-gray-600 whitespace-nowrap ">
                      <div className="flex flex-col">
                        <span className="w-48 h-4 bg-gray-300 rounded-lg">
                          {/* aqui el ID*/}
                        </span>
                        <span className="w-24 h-4 mt-1 bg-gray-300 rounded-lg">
                          {/* aqui la REFERENCIA */}
                        </span>
                      </div>
                    </td>
                    <td className="px-3 py-4 text-sm font-semibold text-gray-600 whitespace-nowrap">
                      <div className="flex flex-col space-y-1">
                        <div className="flex flex-row content-center">
                          <ClockIcon aria-hidden="true" className="w-6 h-6" />
                          <span className="w-16 h-4 mt-1 ml-1 bg-gray-300 rounded-lg">
                            {/* aqui la HORA*/}
                          </span>
                        </div>

                        <div className="flex flex-row content-center">
                          <CalendarIcon
                            aria-hidden="true"
                            className="w-6 h-6"
                          />
                          <span className="w-16 h-4 mt-1 ml-1 bg-gray-300 rounded-lg">
                            {/* aqui la FECHA */}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav
                className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Mostrando pagina<span className="font-medium">{1}</span>{" "}
                    de <span className="font-medium"></span>
                    <span className="font-medium"></span>
                  </p>
                </div>
                <div className="flex justify-between flex-1 sm:justify-end">
                  <button
                    className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-md bg-blue-wompi hover:bg-blue-400"
                  >
                    Anterior
                  </button>
                  <button
                    className={
                      "inline-flex relative items-center py-2 px-4 ml-3 text-sm font-medium text-white bg-blue-wompi hover:bg-blue-400 rounded-md border border-gray-300"
                    }
                  >
                    Siguiente
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="shadow md:hidden">
          <div className="flex px-6 py-3 text-xs font-medium tracking-wider text-center text-gray-700 uppercase rounded-t-lg bg-pantone-blue-100">
            <span className="flex items-center justify-center flex-1">
              {" "}
              Transacciones{" "}
            </span>
          </div>
          <ul className="mt-2 overflow-hidden divide-y divide-gray-200 shadow ">
            <li className="mb-0 sm:relative ">
              <div className="flex flex-row justify-center mt-4 sm:py-2 sm:h-44 md:px-4 sm:mt-0">
                <div className="flex flex-row justify-between px-4 py-4 text-sm sm:pb-4 sm:py-2 sm:justify-center sm:items-center sm:px-2 sm:w-full sm:h-48 md:justify-center md:items-center md:py-2 md:px-4 md:w-1/2 md:h-full">
                  <div className="flex flex-col mt-1 mr-2 space-y-1 text-sm sm:hidden ">
                    <span className="text-blue-400 truncate">Estado</span>
                    <span className="text-blue-400 truncate">Cliente:</span>
                    <span className="inline-block text-blue-400 truncate">
                      Hora
                    </span>
                    <span className="inline-block text-blue-400 truncate">
                      Fecha
                    </span>

                    <span className="inline-block h-10 py-3 text-blue-400 truncate">
                      Datos del pago
                    </span>
                    <span className="inline-block py-2 text-blue-400 truncate sm:py-1 ">
                      Metodo de pago
                    </span>
                  </div>
                  <div className="flex flex-col text-sm text-gray-700 sm:hidden animate-pulse">
                    <span className="w-24 h-4 mt-1 bg-gray-300 rounded-full">
                      {/* aqui el estado */}
                    </span>
                    <div className="w-32 h-4 mt-2 bg-gray-300 rounded-full ">
                      <span>{/* aqui el email */}</span>
                    </div>
                    <div className="flex flex-row content-center mt-1">
                      <ClockIcon aria-hidden="true" className="w-6 h-6" />
                      <span className="h-4 mt-1 ml-1 bg-gray-300 rounded-full w-14">
                        {/* aqui la hora*/}
                      </span>
                    </div>

                    <div className="flex flex-row content-center mb-1">
                      <CalendarIcon aria-hidden="true" className="w-6 h-6" />
                      <span className="h-4 mt-1 ml-1 bg-gray-300 rounded-full w-14">
                        {/* aqui la fecha */}
                      </span>
                    </div>

                    <div className="flex flex-col">
                      <span className="w-32 h-4 mt-2 bg-gray-300 rounded-full">
                        {/* aqui el id */}
                      </span>
                      <span className="w-16 h-4 mt-1 bg-gray-300 rounded-full">
                        {/* aqui la refenerancia*/}
                      </span>
                    </div>
                    <div className="h-6 mt-2 ml-1 bg-gray-300 rounded-lg w-14">
                      <div>{/* aqui la imagen */}</div>
                    </div>
                  </div>

                  <div className="flex-col justify-between hidden w-2/4 h-40 pl-12 text-sm text-gray-700 sm:flex md:hidden animate-pulse">
                    <div className="flex flex-col w-full ">
                      <span className="text-blue-400 truncate animate-none">
                        Cliente
                      </span>
                      <span className="w-32 h-4 bg-gray-300 rounded-full">
                        {/* aqui el email*/}
                      </span>
                    </div>

                    <div className="flex flex-col w-full ">
                      <span className="inline-block text-blue-400 truncate">
                        ID Transaccion
                      </span>
                      <span className="w-32 h-4 bg-gray-300 rounded-full">
                        {/* aqui el ID*/}
                      </span>
                    </div>

                    <div className="flex flex-col w-full ">
                      <span className="inline-block text-blue-400 truncate">
                        Ref. Transaccion
                      </span>
                      <span className="w-32 h-4 bg-gray-300 rounded-full">
                        {/* aqui la REFERENCIA*/}
                      </span>
                    </div>
                  </div>
                  <div className="flex-col content-center justify-between hidden w-2/4 h-40 px-12 mr-2 text-sm sm:flex md:hidden animate-pulse">
                    <div className="flex flex-col">
                      <span className="text-blue-400 truncate">Estado</span>
                      <span className="w-32 h-4 bg-gray-300 rounded-full">
                        {/* aqui EL STADO*/}
                      </span>
                    </div>

                    <div className="flex flex-col">
                      <span className="inline-block text-blue-400 truncate">
                        Hora y Fecha
                      </span>
                      <div className="flex flex-row space-x-2 ">
                        <div className="flex flex-row content-center">
                          <ClockIcon aria-hidden="true" className="w-6 h-6" />
                          <span className="w-16 h-4 mt-1 ml-1 bg-gray-300 rounded-full">
                            {/* aqui la HORA */}
                          </span>
                        </div>

                        <div className="flex flex-row content-center">
                          <CalendarIcon
                            aria-hidden="true"
                            className="w-6 h-6"
                          />
                          <span className="w-16 h-4 mt-1 ml-1 bg-gray-300 rounded-full">
                            {/* aqui la FECHA*/}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      {" "}
                      <span className="inline-block text-blue-400 truncate">
                        Metodo de pago
                      </span>
                      <div className="h-6 ml-1 bg-gray-300 rounded-lg w-14">
                        <div>{/* aqui la imagen */}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col h-auto bg-gray-200 rounded-lg sm:mt-4 sm:relative sm:bottom-0 sm:flex-row sm:justify-between">
                <div className="flex flex-row px-2 py-2 text-sm font-bold text-gray-700 sm:order-last sm:p-2 sm:text-sm">
                  <span>TOTAL:</span>{" "}
                  <div className="inline-block h-5 py-1 ml-1 bg-gray-300 rounded-full w-36 animate-pulse"></div>
                  {/* aqui el monto */}
                </div>
              </div>
            </li>
          </ul>
          <nav
            className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex justify-between flex-1">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-md bg-blue-wompi hover:bg-blue-800"
              >
                Anterior
              </button>
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-white border border-gray-300 rounded-md bg-blue-wompi hover:bg-blue-800"
              >
                Siguiente
              </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
    </>
  );
});
