// import React from "react";
import { useState, useEffect,useLayoutEffect} from "react";
import { useTransactions } from "../hooks/useTransactions";
import { useHistory } from "react-router-dom";
import { DetailsValue } from "../components/interface/detailsValue";
import { SearchFormValue } from "../components/interface/searchFormValue";
import { UserService } from "../Keycloak";
import { getStoreId } from "../hooks/getStoreId";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ordersState } from './order.atom';

export const useHome = () => {
  const [dataOrder, setDataOrder] = useState<any>({});
  const [showNoDateFilter, setShowNoDateFilter] = useState<boolean>(false);
  const [openTransaction, setOpenTransaction] = useState<boolean>(true);
  const [openFilter, setopenFilter] = useState<boolean>(false);
  const history = useHistory();
  const [detailsValue, setDetailsValue] = useState<DetailsValue>({
    transactionId: "",
    paymentGateway: "",
  });
  const [pagination, setpagination] = useState<number>(0);
  const [start, setStart] = useState<number>(0);
  const [limit, setLimit] = useState<number>(1);
  const [startDate, setstartDate] = useState<string>("");
  const [endDate, setendDate] = useState<string>("");
  const [storeAcces, setStoreAcces] = useState<any>(null);
  const [loadingAccess, setLoadingAccess] = useState<any>(false);
  const [isErrorAccess, setIsErrorAccess] = useState<any>(false);

  const [searchValue, setSearchValue] = useState<SearchFormValue>({
    stateOrders: "",
    paymentMethod: "",
    idTransaction: "",
    refTransaction: "",
    emailUser: "",
  });

  useLayoutEffect(() => {
    getStoreId(
      // "https://bizzhub-gateway.hardtech.co:8098/token-claims",
      process.env.REACT_APP_STORE_ID ?? "",
      UserService.getToken() ?? "",
      setLoadingAccess,
      setStoreAcces,
      setIsErrorAccess
    );
  }, []);

  const { orders, isLoading, isError, mutate } = useTransactions(
    // "https://bizzhub-gateway.hardtech.co:8098/engine-api/transactions/",
    process.env.REACT_APP_TRANSACTIONS ?? "",
    UserService.getToken() ?? "",
    storeAcces,
    pagination,
    searchValue.idTransaction,
    searchValue.refTransaction,
    searchValue.stateOrders,
    searchValue.paymentMethod,
    searchValue.emailUser,
    startDate !== "" ? startDate : "",
    endDate !== "" ? endDate : ""
  );
  const [transactions, setTransactions] = useRecoilState(ordersState);

  useEffect(() => {
    if (orders && !isLoading && !loadingAccess) {
        setTransactions({
                   totalPages: orders.totalPages,
                   totalTransactions: orders.totalTransactions,
                  transactions: orders.transactions ?? [],
                   })
      setDataOrder(orders);
    }

    if (orders?.totalTransactions === 0 && !isLoading && !isError) {
      handleReset();
      setShowNoDateFilter(true);
    }
  }, [orders, showNoDateFilter]);

  useEffect(() => {
    setpagination(0);
    setStart(0);
    setLimit(1);
  }, [endDate, startDate]);

  const handleClosedSession = () => {
    UserService.doLogout();
    history.push("/");
  };

  const handleReset = () => {
    setstartDate("");
    setendDate("");
    setSearchValue({
      stateOrders: "",
      paymentMethod: "",
      idTransaction: "",
      refTransaction: "",
      emailUser: "",
    });
    setpagination(0);
    setStart(0);
    setLimit(1);
  };

  useEffect(() => {
    setInterval(() => {
      UserService.updateToken(() => console.log("update token"));
    }, 60000);
  }, []);

  return {
    methods: {
        setDataOrder,
        setDetailsValue,
        setIsErrorAccess,
        setLimit,
        setLoadingAccess,
        setOpenTransaction,
        setSearchValue,
        setStart,
        setStoreAcces,
        setendDate,
        setopenFilter,
        handleClosedSession,
        handleReset,
        setstartDate,
        setpagination,
        mutate,
        setShowNoDateFilter
    },
    variables: {
        dataOrder,
        openTransaction,
        openFilter,
        detailsValue,
        pagination,
        start,
        limit,
        showNoDateFilter,
        searchValue,
        isLoading,
        orders,
        loadingAccess,
        storeAcces,
        isError,
        isErrorAccess
    },
  };
};